<template>
    <div class="col-12">
        <h3 class="mt-3">کۆی گشتی باڵانسی بەکار‌هێنەران</h3>
                <h4>لە بەرواری {{filter.from}} تا بەرواری {{filter.to}}</h4>
        <br>
        <table class="report-table table table-striped" v-if="result.network">
           
            <tbody >
                <tr>
                    <td>کۆی گشتی باڵانسی نێتۆرک</td>
                    <td>{{$service.toIQD(result.network.iqd)}}</td>
                </tr>
                <tr>
                    <td>کۆی گشتی باڵانسی بەکاربراو</td>
                    <td>{{$service.toIQD(result.invoice.iqd)}}</td>
                </tr>
                <tr>
                    <td>کۆی گشتی باڵانسی ڕاکێشراو</td>
                    <td>{{$service.toIQD(result.out.iqd)}}</td>
                </tr>
                <tr>
                    <td>کۆی گشتی باڵانسی ماوەی نێتۆرک </td>
                    <td>{{$service.toIQD(result.balance.iqd)}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script>
    import axios from 'axios'
    export default {
        data() {
            return {
                result: {},
                resetable: false,
                filter: {},
                total_iqd: 0,
                total_usd: 0,
            }
        },
        props: {
            query: {
                type: Object,
            }
        },
        computed: {
            
            auth() {
                return this.$store.getters.auth
            },
        },
        methods: {
            getList() {
                this.list = []
                $('#dataTable').DataTable().destroy();
                axios.post('report/networkBalanceByDate.php' , this.filter)
                    .then(r => {
                        console.log(r);
                        if (r.data.status == 'ok') {
                            this.result = r.data
                        }
                    })
                    .catch(e => {
                        console.log(e);
                    })
            },

        },
        mounted() {
            this.filter = this.$props.query
            $('#dataTable').DataTable();
            this.getList()
        },
    }
</script>
<style>
    .report-table {
        width: 100%;
    }
</style>