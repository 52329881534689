<template>
    <div class="col-12">
        <h3 class="mt-3">ڕاپۆرتی لیستی پسوڵەکانی کڕین</h3>
        <h4>لە بەرواری {{filter.from}} تا بەرواری {{filter.to}}</h4>
        <br>
        <table class="report-table table table-striped">
            <thead class="">
                
                <tr>
                    <th scope="col"> بەروار </th>
                    <th scope="col"> ژ.پسوڵە </th>
                    <th scope="col">کۆمپانیا </th>
                    <!-- <th scope="col">بەکارهێنەر </th> -->
                    <th scope="col">دینار </th>
                    <th scope="col"> دۆلار </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item, index) in invoice_list" :key="index">
                    <td>{{item.company_invoice_date}}</td>
                    <td>
                        <router-link :to="'company_invoice_items/'+item.company_invoice_id">
                            {{item.company_invoice_number}}</router-link>
                    </td>
                    <td>{{item.company_name}}</td>
                    <!-- <td>{{item.user_name}}</td> -->
                    <td>{{$service.toIQD(item.iqd)}}</td>
                    <td>{{$service.toUSD(item.usd)}}</td>
                </tr>
                <tr>
                    <th>
                        <h5>کۆی گشتی</h5>
                    </th>
                    <td></td>
                    <td></td>
                    <th>
                        <h5>{{$service.toIQD(total_iqd)}}</h5>
                    </th>
                    <th>
                        <h5>{{$service.toUSD(total_usd)}}</h5>    
                    </th>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script>
    import axios from 'axios'
    export default {
        data() {
            return {
                invoice_list: [],
                resetable: false,
                filter: {},
                total_iqd: 0,
                total_usd: 0,
            }
        },
        props: {
            query: {
                type: Object,
            }
        },
        computed: {
            company_invoices() {
                return this.$store.getters.company_invoice
            },
            company() {
                return this.$store.getters.company
            },
            stores() {
                return this.$store.getters.store
            },
            auth() {
                return this.$store.getters.auth
            },
            users() {
                return this.$store.getters.users
            }
        },
        methods: {
            getcompany_invoice() {
                this.invoice_list = []
                $('#dataTable').DataTable().destroy();
                axios.post('report/companyInvoiceList.php', this.filter)
                    .then(r => {
                        if (r.data.status == 'ok') {
                            this.invoice_list = r.data.data
                            this.total_iqd = r.data.iqd
                            this.total_usd = r.data.usd
                            this.$nextTick(() => {
                                $('#dataTable').DataTable();
                            })
                        }
                    })
                    .catch(e => {
                        console.log(e);
                    })
            },

        },
        mounted() {
            this.filter = this.$props.query
            $('#dataTable').DataTable();
            this.getcompany_invoice()
        },
    }
</script>
<style>
    .report-table {
        width: 100%;
    }
</style>