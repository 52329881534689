<template>
    <div class="col-12">
        <h3 class="mt-3">ڕاپۆرتی ڕاکێشانی باڵانس  </h3>
        <h4>لە بەرواری {{filter.from}} تا بەرواری {{filter.to}}</h4>
        <br>
        <table class="report-table table table-striped">
            <thead class="">
                <tr>
                    <th scope="col"> بەروار </th>
                    <th scope="col">ناوی کڕیار </th>
                    <th scope="col">بڕ</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item, index) in list" :key="index">
                    <td>{{item.balance_out_date}}</td>
                  
                    <td>{{item.customer_name}} <br> ({{item.customer_phone}})</td>
                    <td>{{$service.toIQD(item.balance_out_iqd)}}</td>
                </tr>
                <tr>
                    <th>
                        <h5>کۆی گشتی</h5>
                    </th>
                    <td></td>
                    <th>
                        <h5>{{$service.toIQD(total_iqd)}}</h5>
                    </th>
                    
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script>
    import axios from 'axios'
    export default {
        data() {
            return {
                list: [],
                resetable: false,
                filter: {},
                total_iqd: 0,
                total_usd: 0,
            }
        },
        props: {
            query: {
                type: Object,
            }
        },
        computed: {
            
            auth() {
                return this.$store.getters.auth
            },
        },
        methods: {
            getList() {
                this.list = []
                $('#dataTable').DataTable().destroy();
                axios.post('report/customerBalanceOut.php', this.filter)
                    .then(r => {
                        if (r.data.status == 'ok') {
                            this.list = r.data.rows
                            this.total_iqd = r.data.iqd
                            this.$nextTick(() => {
                                $('#dataTable').DataTable();
                            })
                        }
                    })
                    .catch(e => {
                        console.log(e);
                    })
            },

        },
        mounted() {
            this.filter = this.$props.query
            $('#dataTable').DataTable();
            this.getList()
        },
    }
</script>
<style>
    .report-table {
        width: 100%;
    }
</style>