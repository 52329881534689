<template>
    <div class="col-12">
        <h3 class="mt-3">ڕاپۆرتی فرۆش و قازانج بەپێی کاڵا</h3>
        <h4>لە بەرواری {{filter.from}} تا بەرواری {{filter.to}}</h4>
        <br>
        <table class="report-table table table-striped">
            <thead class="">
                <tr>
                    <th>#</th>
                    <th scope="col">ناوی کاڵا </th>
                    <th scope="col">بڕی فرۆشراو</th>
                    <!-- <th scope="col"> نرخی فرۆش (د.ع)</th> -->
                    <th scope="col">کۆی فرۆشتن (د.ع)</th>
                    <!-- <th scope="col"> نرخی تێچوو (د.ع)</th> -->
                    <th scope="col">کۆی تێچوو (د.ع) </th>
                    <th scope="col"> بڕی قازانج (د.ع) </th>
                    <th scope="col">ڕێژەی قازانج</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item, index) in invoice_list" :key="index">
                    <td>{{index+1}}</td>
                    <td>{{item.item_name}} <br> ({{item.category_name}})</td>
                    <td>{{item.invoice_items_qty}} {{item.item_unit_tak}}</td>
                    <!-- <td>{{$service.toIQD(item.invoice_items_iqd)}}</td> -->
                    <td>{{$service.toIQD(item.total_iqd)}}</td>
                    <!-- <td>{{$service.toIQD(item.base_iqd)}}</td> -->
                    <td>{{$service.toIQD(item.total_base_iqd)}}</td>
                    <td>{{$service.toIQD(item.revenue_iqd)}}</td>
                    <td>%{{item.revenue_percentage}}</td>
                </tr>
                <tr>
                    <th>
                        <h5>کۆی گشتی</h5>
                    </th>
                    <td></td>
                    <td></td>
                    <!-- <td></td> -->
                    <th>
                        <h5>{{$service.toIQD(total_iqd)}}</h5>
                    </th>
                    <!-- <td></td> -->
                    <th>
                        <h5>{{$service.toIQD(total_base_iqd)}}</h5>
                    </th>
                    <th>
                        <h5>{{$service.toIQD(revenue_iqd)}}</h5>
                    </th>
                    <th>
                        <h5>%{{revenue_percentage}}</h5>
                    </th>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script>
    import axios from 'axios'
    export default {
        data() {
            return {
                invoice_list: [],
                resetable: false,
                filter: {},
                total_iqd: 0,
                total_base_iqd: 0,
                revenue_iqd: 0,
                revenue_percentage: 0,
            }
        },
        props: {
            query: {
                type: Object,
            }
        },
        computed: {
            stores() {
                return this.$store.getters.store
            },
            auth() {
                return this.$store.getters.auth
            },
            users() {
                return this.$store.getters.users
            }
        },
        methods: {
            get_invoice() {
                this.invoice_list = []
                $('#dataTable').DataTable().destroy();
                axios.post('report/out_by_item.php', this.filter)
                    .then(r => {
                        console.log(r);
                        if (r.data.status == 'ok') {
                            this.invoice_list = r.data.data
                            this.total_iqd = r.data.iqd
                            this.total_base_iqd = r.data.base_iqd
                            this.revenue_iqd = r.data.revenue_iqd
                            this.revenue_percentage = r.data.revenue_percentage
                            this.$nextTick(() => {
                                $('#dataTable').DataTable();
                            })
                        }
                    })
                    .catch(e => {
                        console.log(e);
                    })
            },

        },
        mounted() {
            this.filter = this.$props.query
            $('#dataTable').DataTable();
            this.get_invoice()
        },
    }
</script>
<style>
    .report-table {
        width: 100%;
    }
</style>