<template>
    <div class="col-12">
        <h3 class="mt-3">لیستی سەرجەم  بەشداربووان</h3>
        <br>
        <table class="report-table table table-striped" id="dataTable">
            <thead class="">
                <tr>
                    <th scope="col">ناوی کڕیار </th>
                    <th scope="col"> ژمارەی تەلەفۆن </th>
                    <th scope="col">ناونیشان</th>
                    <th scope="col">ئاست</th>
                    <th scope="col">پێوەلکاوی ڕاستەوخۆ</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item, index) in list" :key="index">
                    <td>
                        <router-link :to="'/customers/'+item.customer_id">
                            {{item.customer_name}}
                        </router-link>
                    </td>
                    <td>{{item.customer_phone}}</td>
                    <td>{{item.city_name_ku}}-{{item.town_name_ku}}-{{item.place_name_ku}}-{{item.customer_location}}</td>
                    <td>{{item.customer_genration}}</td>
                    <td>{{item.direct_count}}</td>
                </tr>
                
            </tbody>
        </table>
    </div>
</template>
<script>
    import axios from 'axios'
    export default {
        data() {
            return {
                list: [],
                resetable: false,
                filter: {},
                total_iqd: 0,
                total_usd: 0,
            }
        },
        props: {
            query: {
                type: Object,
            }
        },
        computed: {
            
            auth() {
                return this.$store.getters.auth
            },
        },
        methods: {
            getList() {
                this.list = []
                $('#dataTable').DataTable().destroy();
                axios.post('report/customerDirectCount.php')
                    .then(r => {
                        console.log(r);
                        if (r.data.status == 'ok') {
                            this.list = r.data.list
                            this.$nextTick(() => {
                                $('#dataTable').DataTable();
                            })
                        }
                    })
                    .catch(e => {
                        console.log(e);
                    })
            },

        },
        mounted() {
            this.filter = this.$props.query
            $('#dataTable').DataTable();
            this.getList()
        },
    }
</script>
<style>
    .report-table {
        width: 100%;
    }
</style>