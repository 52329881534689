<template>
    <div class="rtl">
        <div class="container">
            <div class="row no-print">
                <div class="col-3">
                    <div class="form-group">
                        <label for="">لە بەرواری</label>
                        <input type="date" class="form-control" v-model="filters.from" placeholder="">
                    </div>
                </div>
                <div class="col-3">
                    <div class="form-group">
                        <label for="">تا بەرواری</label>
                        <input type="date" class="form-control" v-model="filters.to" placeholder="">
                    </div>
                </div>
            </div>
            <div class="row py-2 no-print">
                <div class="col-12" >
                    <button type="button" @click="getReport(item.id)" v-for="(item, index) in report_list" :key="index" class="btn btn-lg mx-1 my-1 " :class="'btn-'+item.color">{{item.name}}</button>
                </div>
            </div>
            <div class="row">
                <CompanyInvoiceListVue :query="filters" v-if="selected_report == 1"/>
                <InItemsVue :query="filters" v-if="selected_report == 2"/>
                <InvoiceListVue :query="filters" v-if="selected_report == 3"/>
                <OutItemsVue :query="filters" v-if="selected_report == 4"/>
                <OutByItemVue :query="filters" v-if="selected_report == 5"/>
                <BalanceOutVue :query="filters" v-if="selected_report == 6"/>
                <CustomerBalanceDateVue :query="filters" v-if="selected_report == 7"/>
                <CustomerBalanceTotalVue :query="filters" v-if="selected_report == 8"/>
                <CustomerListVue :query="filters" v-if="selected_report == 9"/>
            </div>
        </div>
    </div>
</template>
<script>
import CompanyInvoiceListVue from "./CompanyInvoiceList.vue";
import InItemsVue from "./InItems.vue";
import InvoiceListVue from "./InvoiceList.vue";
import OutItemsVue from "./OutItems.vue";
import OutByItemVue from "./OutByItem.vue";
import BalanceOutVue from "./BalanceOut.vue";
import CustomerBalanceDateVue from "./CustomerBalanceDate.vue";
import CustomerBalanceTotalVue from "./CustomerBalanceTotal.vue";
import CustomerListVue from "./CustomerList.vue";
    export default {
        components: {
            CompanyInvoiceListVue,
            InItemsVue,
            InvoiceListVue,
            OutItemsVue,
            OutByItemVue,
            BalanceOutVue,
            CustomerBalanceDateVue,
            CustomerBalanceTotalVue,
            CustomerListVue,
        },
        data() {
            return {
                filters: {},
                selected_report:0,
                loading: false,
                report_list:[
                    {
                        id: 1,
                        name: 'پسوڵەی کڕین',
                        switch:'بەروار، کۆمپانیا',
                        color:'primary'
                    },
                    {
                        id:2,
                        name:'کڕینی کاڵا',
                        switch:'بەروار، کۆمپانیا، جۆری کاڵا',
                        color:'primary'
                    },
                    {
                        id:3,
                        name:'پسوڵەی فرۆشتن',
                        switch:'بەروار',
                        color:'primary'
                    },
                    {
                        id:4,
                        name:'فرۆشتنی کاڵا',
                        switch:'بەروار، جۆری کاڵا',
                        color:'primary'
                    },
                    {
                        id:5,
                        name:'قازانج و فرۆش',
                        switch:'بەروار، جۆری کاڵا',
                        color:'primary'
                    },
                    {
                        id:6,
                        name:'دەرکردنی باڵانس',
                        switch:'بەروار، جۆری کاڵا',
                        color:'info'
                    },
                    {
                        id:7,
                        name:'باڵانسی کڕیار بەپێی بەروار ',
                        switch:'بەروار، جۆری کاڵا',
                        color:'info'
                    },
                    {
                        id:8,
                        name:'کۆی گشتی باڵانس',
                        switch:'بەروار، جۆری کاڵا',
                        color:'info'
                    },
                    {
                        id:9,
                        name:'لیستی کڕیارەکان',
                        switch:'بەروار، جۆری کاڵا',
                        color:'info'
                    },
                ]
            }
        },
        methods: {
            getReport(id){
                this.selected_report = 0;
                setTimeout(() => {
                    this.selected_report = id;
                }, 1000);
            },
        },
    }
</script>